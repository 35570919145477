import React, { useState } from 'react'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export default function AddQuestion() {
    const [question, setQuestion] = useState('')
    const [options, setOptions] = useState([''])
    const [correctOptions, setCorrectOptions] = useState([])
    const [explanation, setExplanation] = useState('')
    const [questionType, setQuestionType] = useState('single_choice')
    const [imageURL, setImageURL] = useState('')
    const navigate = useNavigate()

    const handleOptionChange = (index, value) => {
        const newOptions = [...options]
        newOptions[index] = value
        setOptions(newOptions)
    }

    const handleAddOption = () => {
        setOptions([...options, ''])
    }

    const handleRemoveOption = (index) => {
        const optionValue = options[index]
        const newOptions = options.filter((_, i) => i !== index)
        setOptions(newOptions)
        setCorrectOptions(correctOptions.filter(o => o !== optionValue))
    }

    const handleSaveQuestion = async () => {
        try {
            const requestBody = {
                question,
                options,
                correctOption: questionType === 'multiple_choice' ? correctOptions : [correctOptions[0]],
                explanation,
                type: questionType,
                imageURL
            }
            console.log('Request Body : ', requestBody)
            const response = await fetch(`${BASE_URL}/api/admin/v1/question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })

            const data = await response.json()

            if (response.ok) {
                setQuestion('')
                setOptions([''])
                setCorrectOptions([])
                setExplanation('')
                setImageURL('')
                navigate('/recent-activity')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleCorrectOptionChange = (optionValue) => {
        if (questionType === 'multiple_choice') {
            setCorrectOptions(prev =>
                prev.includes(optionValue) ? prev.filter(o => o !== optionValue) : [...prev, optionValue]
            )
        } else {
            setCorrectOptions([optionValue])
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Question
                    </Typography>
                    <TextField
                        label='Question'
                        value={question}
                        multiline
                        onChange={(event) => setQuestion(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-question-type-label'>Question Type</InputLabel>
                        <Select
                            labelId='select-question-type-label'
                            id='select-question-type'
                            value={questionType}
                            onChange={(event) => setQuestionType(event.target.value)}
                            label='Question Type'
                        >
                            <MenuItem value='single_choice'>Single Choice</MenuItem>
                            <MenuItem value='multiple_choice'>Multiple Choice</MenuItem>
                        </Select>
                    </FormControl>
                    {options.map((option, index) => (
                        <Box key={index} display="flex" alignItems="center" mt={2}>
                            <TextField
                                label={`Option ${String.fromCharCode(65 + index)}`}
                                value={option}
                                onChange={(event) => handleOptionChange(index, event.target.value)}
                                fullWidth
                                variant='outlined'
                                margin='normal'
                                sx={{ backgroundColor: correctOptions.includes(option) ? '#9cda7d' : 'inherit' }}
                            />
                            <IconButton onClick={() => handleRemoveOption(index)} disabled={options.length <= 1}>
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={handleAddOption}>
                            Add Option
                            <AddIcon />
                        </Button>
                    </Box>
                    <FormControl component="fieldset" margin='normal'>
                        <FormGroup>
                            {options.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={correctOptions.includes(option)}
                                            onChange={() => handleCorrectOptionChange(option)}
                                        />
                                    }
                                    label={`Correct Option ${String.fromCharCode(65 + index)}`}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <TextField
                        label='Explanation'
                        value={explanation}
                        multiline
                        onChange={(event) => setExplanation(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Image URL'
                        value={imageURL}
                        onChange={(event) => setImageURL(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveQuestion}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
