/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Button, Grid, Input, Snackbar, Stack, Table, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/Time'
import { DataGrid } from '@mui/x-data-grid'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'
import { Edit, EditOffOutlined } from '@mui/icons-material'
import CustomThreeDots from '../Loader/CustomThreeDots'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const RecentActivityV2 = () => {
    const [apiCalled, setApiCalled] = React.useState(false)
    const location = useLocation()
    const [showLoader, setShowLoader] = React.useState(true)

    let navigate = useNavigate()
    const [usersList, setUsersList] = React.useState([])
    const [awarded, setAwarded] = React.useState(true)

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const getLeaderboardOfContest = async (contestId) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v2/questions`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setApiCalled(true)
                        setUsersList(data.data.questionsList)
                        setAwarded(data.data.awarded)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        const path = location.pathname
        const contestId = path.substring(22)
        console.log('Contest Id : ', contestId)
        getLeaderboardOfContest(contestId)
        window.scrollTo(0, 0)
    }, [])

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id)
    }

    return (
        <>
            {showLoader && <CustomThreeDots />}
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <h1 className='text-4xl font-bold my-5 text-gray-700 text-center'> Recent Activity </h1>
                    {usersList.length > 0 && (
                        <>
                            <Box sx={{ flexGrow: 2 }}>
                                <Grid align='center' justify='center' spacing={2}>
                                    <Box sx={{ flexGrow: 2 }}>
                                        <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                            <>
                                                <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell className='rounded-tl-lg' align='left'>
                                                                S. No
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left'>Question</StyledTableCell>
                                                            <StyledTableCell align='left'>Added</StyledTableCell>
                                                            {/* <StyledTableCell className='rounded-tr-lg' align='left'>
                                                                Edit
                                                            </StyledTableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {usersList.length > 0 &&
                                                            usersList.map((data, index) => {
                                                                return (
                                                                    <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                                        <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                                        <StyledTableCell align='left'>{data.question}</StyledTableCell>
                                                                        <StyledTableCell align='left'>{showTimeInAgoFormat(data.updatedAt)}</StyledTableCell>
                                                                        {/* <StyledTableCell align='left' onClick={() => handleEditQuestion(data._id)}>
                                                                            <Edit sx={{ cursor: 'pointer' }} />
                                                                        </StyledTableCell> */}
                                                                    </StyledTableRow>
                                                                )
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                            Success!!!
                                        </Alert>
                                    </Snackbar>
                                </Stack>

                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                            Error!!!
                                        </Alert>
                                    </Snackbar>
                                </Stack>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default RecentActivityV2
