/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'
import { useEffect } from 'react'
import { Grid, Snackbar, Stack, Table, TableBody, IconButton, Modal, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CustomThreeDots from '../Loader/CustomThreeDots'
import DeleteIcon from '@mui/icons-material/Delete'
import { Edit } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const RecentActivity = () => {
    const [apiCalled, setApiCalled] = React.useState(false)
    const location = useLocation()
    const [showLoader, setShowLoader] = React.useState(true)

    let navigate = useNavigate()
    const [usersList, setUsersList] = React.useState([])
    const [awarded, setAwarded] = React.useState(true)

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const getLeaderboardOfContest = async (contestId) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/questions`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setApiCalled(true)
                        setUsersList(data.data.questionsList)
                        setAwarded(data.data.awarded)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        getLeaderboardOfContest()
    }, [])

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
    const [selectedQuestionId, setSelectedQuestionId] = React.useState(null)

    const handleDeleteClick = (questionId) => {
        setSelectedQuestionId(questionId)
        setOpenDeleteModal(true)
    }

    const handleCloseModal = () => {
        setOpenDeleteModal(false)
        setSelectedQuestionId(null)
    }

    const handleConfirmDelete = async () => {
        try {
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            }
            const response = await fetch(`${BASE_URL}/api/admin/question/${selectedQuestionId}`, requestOptions)
            if (response.ok) {
                setUsersList(usersList.filter((question) => question._id !== selectedQuestionId))
                setOpenSuccessAlert(true)
            } else {
                setOpenErrorAlert(true)
            }
        } catch (error) {
            setOpenErrorAlert(true)
        } finally {
            handleCloseModal()
        }
    }

    const questionTypesV1 = ['single_choice', 'multiple_choice']
    const handleEditQuestion = (question_data) => {
        if (questionTypesV1.includes(question_data.type)) {
            navigate('/edit-question', { state: { questionData: question_data } });
        } else if (question_data.type === 'dual_choice') {
            navigate('/edit-question-v3', { state: { questionData: question_data } });
        } else if (question_data.type === 'arrange') {
            navigate('/edit-question-v2', { state: { questionData: question_data } });
        }
    };

    const getQuestionText = (text) => {
        return (
            <>
                {text.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </>
        )
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <h1 className='text-4xl font-bold my-5 text-gray-700 text-center'> Recently added questions {usersList.length > 0 && '- ' + usersList.length} </h1>
                    {usersList.length > 0 && (
                        <>
                            <Box sx={{ flexGrow: 2 }}>
                                <Grid align='center' justify='center' spacing={2}>
                                    <Box sx={{ flexGrow: 2 }}>
                                        <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                            <>
                                                <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell className='rounded-tl-lg' align='left'>
                                                                S. No
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left'>Question</StyledTableCell>
                                                            <StyledTableCell align='left'>Type</StyledTableCell>
                                                            <StyledTableCell align='left'>Last Updated </StyledTableCell>
                                                            <StyledTableCell align='left'>Edit</StyledTableCell>
                                                            <StyledTableCell align='left'>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {usersList.length > 0 &&
                                                            usersList.map((data, index) => (
                                                                <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                                    <StyledTableCell align='left'>{getQuestionText(data.question)}</StyledTableCell>
                                                                    <StyledTableCell align='left'>{data.type}</StyledTableCell>
                                                                    <StyledTableCell align='left'>{showTimeInAgoFormat(data.updatedAt)}</StyledTableCell>
                                                                    <StyledTableCell align='left'>
                                                                        <IconButton onClick={() => handleEditQuestion(data)}>
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align='left'>
                                                                        <IconButton onClick={() => handleDeleteClick(data._id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                            Success!!!
                                        </Alert>
                                    </Snackbar>
                                </Stack>

                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                            Error!!!
                                        </Alert>
                                    </Snackbar>
                                </Stack>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            <Modal open={openDeleteModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'white',
                        border: '1px solid gray',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography variant='h6' component='h2'>
                        Confirm Delete
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Are you sure you want to delete this question?
                    </Typography>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' color='error' onClick={handleConfirmDelete} sx={{ mr: 2 }}>
                            Confirm
                        </Button>
                        <Button variant='outlined' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default RecentActivity
