import * as React from 'react'
import { useEffect } from 'react'
import { Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { getAdminPassword, getAdminUsername } from '../../Auth/AuthenticateAdmin'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Login = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const handleLogin = () => {
        const adminUsername = getAdminUsername()
        const adminPassword = getAdminPassword()
        console.log('Admin : ', adminUsername, adminPassword)
        console.log('User : ', username, password)
        if (username === adminUsername && password === adminPassword) {
            localStorage.setItem('isAuth', true)
            navigate('/users')
        } else {
            setOpenErrorAlert(true)
        }
    }

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='text-4xl font-bold text-gray-700 my-5'> Admin Login</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <TextField
                        disablePortal
                        placeholder='Enter Username'
                        id='combo-box-demo'
                        value={username}
                        sx={{ width: '50%' }}
                        onChange={(event) => {
                            setUsername(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <TextField
                        type='password'
                        disablePortal
                        placeholder='Enter Password'
                        id='combo-box-demo'
                        value={password}
                        sx={{ width: '50%' }}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                    <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='success' onClick={handleLogin}>
                        Login
                    </Button>
                </Grid>
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default Login
