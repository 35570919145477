/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import checkIcon from '../../Assets/checked.png'
import './Home.css'
import DrawerNav from '../DrawerNav/DrawerNav'
import CustomThreeDots from '../Loader/CustomThreeDots'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function Home() {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }

    const [contests, setContests] = useState([])
    const [topicsList, setTopicsList] = useState([])
    const [upcomingContests, setUpcomingContests] = useState([])
    const [liveContests, setLiveContests] = useState([])
    const [completedContests, setCompletedContests] = useState([])
    const [hiddenContests, setHiddenContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const handleEditClick = (id) => {
        navigate('/edit-contest/' + id)
    }

    const [showLoader, setShowLoader] = useState(true)

    const getTopicsList = async () => {
        try {
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <div className='text-center ml-4 mt-4 text-2xl'>Welcome to Pawabi dashboard</div>
            </Box>
        </>
    )
}

export default Home
