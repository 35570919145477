import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { DangerousTwoTone, DragHandle, DragHandleTwoTone, Duo, GifTwoTone, Home, LocalActivity, LocalActivityOutlined, Logout, MilitaryTech, Person, QuestionAnswer, QuestionAnswerOutlined, QuestionAnswerRounded, QuestionMark, QuestionMarkOutlined, Topic, TwoKPlus, TwoMp, TwoWheeler, Upload } from '@mui/icons-material'
function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        // {
        //     value: 'Home',
        //     route: '',
        //     icon: <Home />
        // },
        // {
        //     value: 'Add Topic',
        //     route: 'add-topic',
        //     icon: <Topic />
        // },
        // {
        //     value: 'Add Question',
        //     route: 'add-question',
        //     icon: <QuestionMark />
        // },
    ]

    const mainListItems = [
        // {
        //     value: 'Topics',
        //     route: 'topics',
        //     icon: <Topic />
        // },
        // {
        //     value: 'Questions',
        //     route: 'recent-activity',
        //     icon: <QuestionMark />
        // },
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Users',
            route: 'users',
            icon: <Person />
        },
        {
            value: 'Image Upload',
            route: 'image-upload',
            icon: <Upload />
        },
        {
            value: 'Add Question',
            route: 'add-question',
            icon: <QuestionAnswerRounded />
        },
        {
            value: 'Add Drag Question',
            route: 'add-question-v2',
            icon: <DragHandleTwoTone />
        },
        {
            value: 'Add Dual Choice Question',
            route: 'add-question-v3',
            icon: <QuestionMarkOutlined />
        },
        {
            value: 'Questions',
            route: 'recent-activity',
            icon: <LocalActivity />
        },
        // {
        //     value: 'Drag and Drop Questions',
        //     route: 'recent-activity-v2',
        //     icon: <LocalActivity />
        // }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {mainListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={12} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
