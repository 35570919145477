import React, { useState } from 'react'
import { Box, Button, TextField, Typography, FormControl, Select, MenuItem, IconButton, InputLabel } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export default function AddQuestion() {
    const [question, setQuestion] = useState('')
    const [questionOne, setQuestionOne] = useState('')
    const [questionTwo, setQuestionTwo] = useState('')
    const [optionsSet1, setOptionsSet1] = useState(['', '', '', ''])
    const [optionsSet2, setOptionsSet2] = useState(['', '', '', ''])
    const [correctOptionSet1, setCorrectOptionSet1] = useState('')
    const [correctOptionSet2, setCorrectOptionSet2] = useState('')
    const [explanation, setExplanation] = useState('')
    const [imageURL, setImageURL] = useState('')
    const navigate = useNavigate()

    const handleOptionChange = (setOptions, options, index, value) => {
        const newOptions = [...options]
        newOptions[index] = value
        setOptions(newOptions)
    }

    const handleAddOption = (setOptions, options) => {
        setOptions([...options, ''])
    }

    const handleRemoveOption = (setOptions, setCorrectOption, options, index) => {
        const newOptions = options.filter((_, i) => i !== index)
        setOptions(newOptions)
        setCorrectOption('')
    }

    const handleSaveQuestion = async () => {
        try {
            const requestBody = {
                question,
                questionOne,
                questionTwo,
                optionsOne: optionsSet1,
                optionsTwo: optionsSet2,
                correctOptionOne: [correctOptionSet1],
                correctOptionTwo: [correctOptionSet2],
                explanation,
                type: 'dual_choice',
                imageURL
            }
            console.log('Request Body : ', requestBody)
            const response = await fetch(`${BASE_URL}/api/admin/v3/question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })

            const data = await response.json()

            if (response.ok) {
                setQuestion('')
                setQuestionOne('')
                setQuestionTwo('')
                setOptionsSet1(['', '', '', ''])
                setOptionsSet2(['', '', '', ''])
                setCorrectOptionSet1('')
                setCorrectOptionSet2('')
                setExplanation('')
                setImageURL('')
                navigate('/recent-activity')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Dual Question
                    </Typography>
                    <TextField
                        label='Question'
                        value={question}
                        multiline
                        onChange={(event) => setQuestion(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Question One'
                        value={questionOne}
                        multiline
                        onChange={(event) => setQuestionOne(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Question Two'
                        value={questionTwo}
                        multiline
                        onChange={(event) => setQuestionTwo(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Typography variant='h6' sx={{ mt: 2 }}>
                        Options Set 1
                    </Typography>
                    {optionsSet1.map((option, index) => (
                        <Box key={index} display="flex" alignItems="center" mt={2}>
                            <TextField
                                label={`Option 1.${index + 1}`}
                                value={option}
                                onChange={(event) => handleOptionChange(setOptionsSet1, optionsSet1, index, event.target.value)}
                                fullWidth
                                variant='outlined'
                                margin='normal'
                            />
                            <IconButton onClick={() => handleRemoveOption(setOptionsSet1, setCorrectOptionSet1, optionsSet1, index)} disabled={optionsSet1.length <= 1}>
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={() => handleAddOption(setOptionsSet1, optionsSet1)}>
                            Add Option
                            <AddIcon />
                        </Button>
                    </Box>
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='correct-option-set1-label'>Correct Option Set 1</InputLabel>
                        <Select
                            labelId='correct-option-set1-label'
                            value={correctOptionSet1}
                            onChange={(event) => setCorrectOptionSet1(event.target.value)}
                            label='Correct Option Set 1'
                        >
                            {optionsSet1.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant='h6' sx={{ mt: 2 }}>
                        Options Set 2
                    </Typography>
                    {optionsSet2.map((option, index) => (
                        <Box key={index} display="flex" alignItems="center" mt={2}>
                            <TextField
                                label={`Option 2.${index + 1}`}
                                value={option}
                                onChange={(event) => handleOptionChange(setOptionsSet2, optionsSet2, index, event.target.value)}
                                fullWidth
                                variant='outlined'
                                margin='normal'
                            />
                            <IconButton onClick={() => handleRemoveOption(setOptionsSet2, setCorrectOptionSet2, optionsSet2, index)} disabled={optionsSet2.length <= 1}>
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={() => handleAddOption(setOptionsSet2, optionsSet2)}>
                            Add Option
                            <AddIcon />
                        </Button>
                    </Box>
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='correct-option-set2-label'>Correct Option Set 2</InputLabel>
                        <Select
                            labelId='correct-option-set2-label'
                            value={correctOptionSet2}
                            onChange={(event) => setCorrectOptionSet2(event.target.value)}
                            label='Correct Option Set 2'
                        >
                            {optionsSet2.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Explanation'
                        value={explanation}
                        multiline
                        onChange={(event) => setExplanation(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Image URL'
                        value={imageURL}
                        onChange={(event) => setImageURL(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveQuestion}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
