import React, { useState } from 'react'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export default function AddQuestionV2() {
    const [question, setQuestion] = useState('')
    const [options, setOptions] = useState([
        { id: 1, title: '' },
        { id: 2, title: '' },
        { id: 3, title: '' },
        { id: 4, title: '' }
    ])
    const [correctOrder, setCorrectOrder] = useState([
        { id: 1, title: '' },
        { id: 2, title: '' },
        { id: 3, title: '' },
        { id: 4, title: '' }
    ])
    const [explanation, setExplanation] = useState('')
    const [imageURL, setImageURL] = useState('')
    const navigate = useNavigate()

    const handleOptionChange = (index, value) => {
        const newOptions = [...options]
        newOptions[index] = { ...newOptions[index], title: value }
        setOptions(newOptions)

        const newCorrectOrder = [...correctOrder]
        const correctIndex = newCorrectOrder.findIndex(opt => opt.id === newOptions[index].id)
        if (correctIndex > -1) {
            newCorrectOrder[correctIndex] = { ...newCorrectOrder[correctIndex], title: value }
            setCorrectOrder(newCorrectOrder)
        }
    }

    const handleAddOption = () => {
        const newOption = { id: options.length + 1, title: '' }
        setOptions([...options, newOption])
        setCorrectOrder([...correctOrder, newOption])
    }

    const handleRemoveOption = (index) => {
        const optionToRemove = options[index]
        setOptions(options.filter((_, i) => i !== index))
        setCorrectOrder(correctOrder.filter(opt => opt.id !== optionToRemove.id))
    }

    const handleSaveQuestion = async () => {
        try {
            const requestBody = {
                question,
                options,
                correctOption: correctOrder,
                explanation,
                type: 'arrange',
                imageURL
            }
            console.log('Request Body : ', requestBody)
            const response = await fetch(`${BASE_URL}/api/admin/v2/question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })

            const data = await response.json()

            console.log('Data : ', data)

            if (response.ok) {
                setQuestion('')
                setOptions([
                    { id: 1, title: '' },
                    { id: 2, title: '' },
                    { id: 3, title: '' },
                    { id: 4, title: '' }
                ])
                setCorrectOrder([
                    { id: 1, title: '' },
                    { id: 2, title: '' },
                    { id: 3, title: '' },
                    { id: 4, title: '' }
                ])
                setExplanation('')
                setImageURL('')
                navigate('/recent-activity')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return

        const items = Array.from(correctOrder)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setCorrectOrder(items)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Drag and Drop Question
                    </Typography>
                    <TextField
                        label='Question'
                        value={question}
                        multiline
                        onChange={(event) => setQuestion(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    {options.map((option, index) => (
                        <Box key={option.id} display="flex" alignItems="center" mt={2}>
                            <TextField
                                label={`Option ${String.fromCharCode(65 + index)}`}
                                value={option.title}
                                onChange={(event) => handleOptionChange(index, event.target.value)}
                                fullWidth
                                variant='outlined'
                                margin='normal'
                            />
                            <IconButton onClick={() => handleRemoveOption(index)} disabled={options.length <= 4}>
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={handleAddOption}>
                            Add Option
                            <AddIcon />
                        </Button>
                    </Box>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="correctOrder">
                            {(provided) => (
                                <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ listStyle: 'none', p: 0 }}>
                                    {correctOrder.map((option, index) => (
                                        <Draggable key={option.id} draggableId={String(option.id)} index={index}>
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f9f9f9' }}
                                                >
                                                    {option.title || `Option ${String.fromCharCode(65 + index)}`}
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <TextField
                        label='Explanation'
                        value={explanation}
                        multiline
                        onChange={(event) => setExplanation(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Image URL'
                        value={imageURL}
                        onChange={(event) => setImageURL(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveQuestion}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
